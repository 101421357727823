export function emailRules(): ((v: string) => boolean | string)[] {
  const regexp = /^([a-z0-9\.-]+)@([a-z0-9\-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/i;
  return [
    (v: string): boolean | string => !!v || 'e-mail skal angives',
    (v: string): boolean | string => regexp.test(v) || 'e-mail skal være af formatet xxx@yyy.zzz'
  ];
}

export function passwordRules(): ((v: string) => boolean | string)[] {
  const regexp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,32}/;
  return [
    (v: string): boolean | string => !!v || 'Kodeord skal angives',
    (v: string): boolean | string =>
      regexp.test(v) || 'Kodeord skal være mellem 8 og 32 tegn. 1 stort bogstav, 1 lille bogstav og 1 tal'
  ];
}

export function phoneRules(mandatory: boolean): ((v: string) => boolean | string)[] {
  const regexp = /^([0-9]){8}$/;
  if (mandatory) {
    return [
      (v: string): boolean | string => !!v || 'Telefonnummer skal angives',
      (v: string): boolean | string => regexp.test(v) || 'Telefonnummer skal være 8 tal'
    ];
  } else {
    return [(v: string): boolean | string => regexp.test(v) || 'Telefonnummer skal være 8 tal'];
  }
}

export function websiteRules(): ((v: string) => boolean | string)[] {
  const regexp = /^((http|https)(:\/\/)([a-z0-9]+){1}(\.[a-z0-9]{2,}){1,3}(\/)*([\/a-z0-9\?\&\=\.]+)*)*$/i;
  return [
    (v: string): boolean | string =>
      regexp.test(v) || 'Ikke tilladt web site format. Brug f.eks. http(s)://www.dronepilot.dk/test?parameter=1'
  ];
}
